




























import {
  Component, Vue, Prop, Watch 
} from 'vue-property-decorator';

import Close from '@/assets/icons/Close.vue';
import Arrow from '@/assets/icons/Arrow.vue';

@Component({
  components: {
    Close,
    Arrow
  }
})
export default class ExamsHeaderAccordion extends Vue {
  @Prop() title!: string;

  private relatedExamsIsOpen = true;

  setOpeningRelatedExams() {
    this.relatedExamsIsOpen = !this.relatedExamsIsOpen;
  }

  @Watch('$responsive.contentwidth')
  changeCheckOpenWidth() {
    if (window.innerWidth <= 1200 && this.relatedExamsIsOpen !== true) {
      this.relatedExamsIsOpen = true;
    }
  }

  @Watch('relatedExamsIsOpen')
  emitOpeningRelatedExams() {
    this.$emit('opening-related-exams', this.relatedExamsIsOpen);
  }
}
