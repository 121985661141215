






























import { Component, Vue, Prop } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

const TEXT_DEFAULT = 'Algo deu errado! Não foi possível carregar esse conteúdo. Tente novamente.';

@Component({
  components: {
    Modal
  }
})
export default class ModalError extends Vue {
  @Prop({ default: TEXT_DEFAULT }) textErro!: string;

  handleClickTryAgain() {
    this.$emit('try-again');
  }

  goToDashboard() {
    this.$router.push({
      name: 'begin'
    });
  }
}
